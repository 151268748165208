import React from 'react'
import './Login.css';

const Background = () => {
  return (
    <div>
        <div className="one place1"></div>
        <div className="one place2"></div>
        <div className="one place3"></div>
        <div className="one place4"></div>
    </div>
  )
}

export default Background